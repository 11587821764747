import { render, staticRenderFns } from "./_IPOCode.vue?vue&type=template&id=293f33f0&scoped=true&"
import script from "./_IPOCode.vue?vue&type=script&lang=js&"
export * from "./_IPOCode.vue?vue&type=script&lang=js&"
import style0 from "./_IPOCode.vue?vue&type=style&index=0&id=293f33f0&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "293f33f0",
  null
  
)

/* custom blocks */
import block0 from "./ipo-details.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvercel%2Fpath0%2Fpages%2Finvesting%2Fservices%2Fipo-subscriptions%2F_IPOCode.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingAnimation: require('/vercel/path0/components/ui/LoadingAnimation.vue').default,TwoColumnBlock: require('/vercel/path0/components/blocks/TwoColumnBlock.vue').default,CImage: require('/vercel/path0/components/ui/c-image.vue').default,DownloadCard: require('/vercel/path0/components/DownloadCard.vue').default})
